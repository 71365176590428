import React from 'react'
import { Dialog } from '@headlessui/react'
import {FacebookShareButton , TwitterShareButton , LinkedinShareButton , WhatsappShareButton} from 'react-share'
import {FacebookIcon , TwitterIcon , LinkedinIcon , WhatsappIcon} from 'react-share'
import {BiX} from 'react-icons/bi'

function Sharebtn({isOpen , setIsOpen , url}) {

    function closeModal() {
        setIsOpen(false)
    }

    
return (
        <>
            <div>
        <button
          type="button">
          Share
        </button>
      </div>
     
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="flex justify-center flex-col items-center bg-nav-color text-white duration-1000 w-full h-40 fixed bottom-0 z-50 overflow-y-auto rounded-t-xl ">
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />


      <div className="text-center font-general mt-5">Share Post on your favourite social media platform..</div>

        <div className="flex justify-around w-full h-full z-20 lg:justify-center">
        <FacebookShareButton url={url} quote={"Alumni Hub"} hashtag={"#BEnBEYOND"} className="flex flex-col justify-center items-center lg:mx-8 transform hover:-translate-y-2 duration-500">
            <FacebookIcon size={40} round={true}/><p>Facebook</p>
        </FacebookShareButton>
        <LinkedinShareButton url={url} quote={"Alumni Hub"} hashtag={"#BEnBEYOND"} className="flex flex-col justify-center items-center lg:mx-8 transform hover:-translate-y-2 duration-500">
            <LinkedinIcon size={40} round={true}/><p>LinkedIn</p>
        </LinkedinShareButton>
        <TwitterShareButton url={url} quote={"Alumni Hub"} hashtag={"#BEnBEYOND"} className="flex flex-col justify-center items-center lg:mx-8 transform hover:-translate-y-2 duration-500">
            <TwitterIcon size={40} round={true}/><p>Twitter</p>
        </TwitterShareButton>
        <WhatsappShareButton url={url} quote={"Alumni Hub"} hashtag={"#BEnBEYOND"} className="flex flex-col justify-center items-center lg:mx-8 transform hover:-translate-y-2 duration-500">
            <WhatsappIcon size={40} round={true}/><p>Whatsapp</p>
        </WhatsappShareButton>
        </div>


      <button onClick={closeModal} className="absolute top-px right-px lg:top-1 lg:right-5"><BiX className="w-5 h-5 lg:w-8 lg:h-8"/></button>
    </Dialog>
   
        </>
    )
}

export default Sharebtn
