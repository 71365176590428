import React,{useState} from 'react'
import {BsBookmarks,BsBookmarksFill} from 'react-icons/bs'

function SaveBtn({save}) {

    
    return (
        <div >
            
            {
                !save ? (
                    <div className="flex">
                    <BsBookmarks className="text-xl"/>
                    <p className="text-md ml-2 hidden lg:block">Save Post</p>
                    </div>
                ):(
                    <div className="flex">
                    <BsBookmarksFill className="text-xl" />
                    <p className="text-md ml-2 hidden lg:block">Unsave Post</p>
                    </div>
                )
            }
        </div>
    )
}

export default SaveBtn
