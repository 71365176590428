import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../Components/Footer'
import LandingPageNavbar from '../Components/Navbar/LandingPage-navbar'
import {useNavigate} from 'react-router-dom'

import { setUserEmailAction, checkUserAction } from '../actions/auth'

function EmailPage() {

    const {userEmail, isGetOTPButtonLoading} = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const setUserEmail = (e) => {
        e.preventDefault();
        dispatch(setUserEmailAction(e.target.value));
    }

    const checkUser = (e) => {
        e.preventDefault();
        
            dispatch(checkUserAction(userEmail, navigate));

        
    }
    const handleKeypress = e => {
        if (e.keyCode === 'Enter') {
          checkUser();
        }
      }

    return (
        <>
            <div className="bg-gradient-to-b from-background-color to-white ">
                <LandingPageNavbar/>
                    <form>
                    <div className="container md:container md:mx-auto pt-60">
                        <div className="h-80 w-72 sm:w-3/4 lg:w-1/2 p-2 sm:p-4 bg-nav-color text-white rounded-2xl font-general shadow-2xl mx-auto">
                            <h1 className="text-3xl py-8">Enter Ves Email Id</h1>
                            <div className="mb-3 pt-0">
                                <input 
                                type="email" 
                                placeholder="@ves.ac.in" 
                                className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring xl:w-3/5" 
                                value={userEmail}
                                onChange={setUserEmail}
                                onKeyPress={handleKeypress}
                                />
                                <button onClick={checkUser} href="#" type="submit" className="bg-background-color cursor-pointer text-white block px-3 py-2 mt-16 w-40 mx-auto rounded-md text-base font-medium">
                                    {isGetOTPButtonLoading ? 'Loading...':'GET OTP'}
                                </button>
                            </div>
                        </div>
                    </div>
                    </form>
                <Footer/>
          </div>
        </>
    )
}

export default EmailPage
