import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {FaLinkedin,FaUserEdit,FaGithub } from 'react-icons/fa';
import {GrMail} from 'react-icons/gr'
import {IoReturnDownBackOutline} from 'react-icons/io5'
import {RiDeleteBin5Fill} from 'react-icons/ri'
import {BiShareAlt} from 'react-icons/bi'
import {Link, useNavigate} from 'react-router-dom'
import { userDetailsAction} from '../actions/auth'
import { postSaveAction, removeSelectedPost, userPostDeleteAction, userSavedPostsAction } from '../actions/post';
import SaveBtn from './SaveBtn';
import Sharebtn from './Sharebtn';
import Dialog from './Dialog';
import moment from 'moment'
import Loader from './Loader';


function DetailPost({key,name,title,message,email,github,linkedin,timestamp,profilePic,postImage,tags,isAlumni,post_id}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const {user} = useSelector(state => state.auth);
    useEffect(() => {
        dispatch(userDetailsAction());
        dispatch(userSavedPostsAction())
        setSave(Newarr?.includes(parseInt(post_id)))
        return () =>{
          dispatch(removeSelectedPost());
        }
    },[] )


    const {userSavedPosts,isPostDetailLoading} = useSelector(state => state?.post);
    const Newarr = userSavedPosts?.map(p=>p.id)
    

    const [save,setSave] = useState()
    const [isOpen, setIsOpen] = useState(false)
    

    const SaveHandler = () => {
        dispatch(postSaveAction(post_id))
        setSave(!save)
    } 
    function openModal() {
        setIsOpen(true)
    }

    const DeleteUserPostHandler = () => {
         window.confirm("Are u sure You want to delete post ?") && dispatch(userPostDeleteAction(post_id,navigate)) 
    }


    const [isShowDialog, setIsShowDialog] = useState(false);

  const handleSubmit = () => {
    dispatch(userPostDeleteAction(post_id,navigate))
  }

  const handleCloseDialog = () => {
    setIsShowDialog(!isShowDialog)
  }
  const dateTime = moment(timestamp).format('LL');

  const DialogActions = (btnColor) => {
    return (
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className={`w-full inline-flex justify-center rounded-md border 
          border-transparent shadow-sm px-4 py-2 bg-nav-color text-base
          font-medium text-white focus:outline-none
          focus:ring-2 focus:ring-offset-2 
          focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}
          onClick={handleSubmit}
        >
          Yes
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md
          border border-gray-300 shadow-sm px-4 py-2 bg-white text-base 
          font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleCloseDialog}
        >
          No
        </button>
      </div>
    );
  };
    return (
        <div className="min-h-screen w-full flex justify-center items-center mt-12 mb-4">
            <div className="flex flex-col justify-around items-center bg-nav-color rounded-2xl mt-16 mx-2 lg:mx-8 shadow-2xl">

                    <div className="flex flex-row w-full justify-end">
                        {
                             (user?.user?.email) == (email) &&(
                                 <>
                                <Link to={`/editPost/${post_id}`}><div className="flex bg-white text-nav-color w-max mx-2 mt-2 mb-4 rounded-lg px-3 py-1">
                                     <FaUserEdit className="text-xl" />
                                    <p className="text-md ml-2 hidden lg:block">Edit Post</p>
                                </div></Link>
                                    <div className="flex  bg-white text-nav-color w-max mx-2 mt-2 mr-4 mb-4 rounded-lg px-3 py-1 cursor-pointer" onClick={handleCloseDialog}>
                                    <RiDeleteBin5Fill className="text-xl"  />
                                    <p className="text-md ml-2 hidden lg:block" >Delete Post</p>
                                    </div>
                                    {isShowDialog && (
        <Dialog
          title={"Delete Post"}
          handleCloseDialog={handleCloseDialog}
          actionsPannel={DialogActions('bg-green')}
          
          color={'bg-green'}
        >
          Are u sure You want to delete post ?
        </Dialog>
      )}
                                 </>
                                
                                ) 
                            }

                    </div>

                    <div className="flex flex-col lg:flex-row">
                    <div className="w-full p-2 flex flex-col-reverse justify-center items-center lg:w-1/2 lg:p-8 ">
                        {
                            postImage && (
                        <img src={postImage} alt="postImg" className="object-cover h-full w-auto"></img>
                            )
                        }
                        <div className="flex justify-center items-center mb-4 text-white">
                            <img className= {`rounded-full w-16 h-16 border-2 mr-4 ${isAlumni ? "border-blue-400" : "border-red-400" } `}  src={profilePic} alt="profilePic"></img>
                            <div>
                                <p className="font-bold text-lg">{name}</p>
                                <p className="text-sm">{dateTime}</p>
                                <p className="text-sm break-all">{email}</p>
                            </div>
                        </div>
                    </div>
                    
                
                <div className="w-full p-2 lg:w-1/2 lg:p-8  lg:h-full text-white flex flex-col justify-between">
                
                    <div className="flex justify-center items-center text-3xl my-4 font-bold break-all">
                        {title}
                    </div>
                    
                    <div className='break-all'>
                        {message}
                    </div>
                    <div className="mt-4 mb-2">
                        Connect with {name} here !
                    </div>
                        <div className="flex justify-evenly text-2xl">
                            <a href={`mailto:${email}`} target="_blank"><GrMail className=" cursor-pointer transform hover:-translate-y-2 duration-500"/></a>
                            <a href={`https://github.com/`+github} target="_blank"><FaGithub className=" cursor-pointer transform hover:-translate-y-2 duration-500"/></a>
                            <a href={linkedin} target="_blank"><FaLinkedin className=" cursor-pointer transform hover:-translate-y-2 duration-500"/></a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row">
                        <div className="flex  bg-white text-nav-color w-max mx-2 mt-2 mr-4 mb-4 rounded-lg px-3 py-1 cursor-pointer" onClick={SaveHandler}>
                            <SaveBtn save={save} />
                        </div>
                        <div onClick={openModal} className="flex  bg-white text-nav-color w-max mx-2 mt-2 mr-4 mb-4 rounded-lg px-3 py-1 cursor-pointer">
                            <BiShareAlt className="text-xl" />
                            <p className="text-md ml-2 hidden lg:block"><Sharebtn isOpen={isOpen} setIsOpen={setIsOpen} url={`https://alumnihub.isavesit.org.in/#/post/${post_id}`}/></p>
                        </div>
                </div>
            </div>

            <Link to="/posts"><div className="fixed bottom-4 right-4 h-10 w-10 bg-white z-50 border-2 border-black rounded-md lg:w-max flex justify-center items-center lg:p-2">
                <span className="hidden lg:block text-lg mr-2">Back</span>
                <IoReturnDownBackOutline className="h-full w-full"/>
            </div></Link>
        </div>
    )
}

export default DetailPost
