import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Components/Navbar/Navbar';
import LeftSidebar from '../Components/Sidebar/LeftSidebar';
import RightSidebar from '../Components/Sidebar/RightSidebar';
import Post from '../Components/Post'
import { postListAction } from '../actions/post'
import Loader from '../Components/Loader';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';


function PostsFeedPage() {

    const dispatch = useDispatch();
    const {isPostListLoading,posts,postListError} = useSelector(state => state.post);
    

    useEffect(() => {
        dispatch(postListAction());

    },[] )
    return (
        <>
          <Navbar/>
            <div className="flex bg-background-color font-general min-h-screen">
                <div className="lg:w-1/4 box-border px-4"><LeftSidebar /></div>
                <div className="w-full lg:w-2/4 box-border px-4">

                    {/*  */}
                    <div className="mt-32 mb-4">
                    {isPostListLoading ? <Loader/>
                : postListError ? <div>OOPS! Something went horribly wrong </div>
                    : <div>
                        
                        {posts?.slice(0).reverse().map(p => (
                            
                            <Post
                            key={p.id}
                            post_id ={p.id}
                            name={`${p.user.first_name} ${p.user.last_name}`}
                            title={p.heading}
                            message = {p.description}
                            email={p.user.email}
                            timestamp={p.created_at}
                            profilePic={`https://alumnihub.isavesit.org.in${p.profile.profile_image}`}

                            postImage={p.post_image ? `https://alumnihub.isavesit.org.in${p.post_image}` : null}
                            tags={p?.tags}
                            isAlumni={true}
                            authorProfile = {p.profile}
                            author_id = {p?.user?.id}
                            

                            />
                        
                        ))}
                    </div>
            }
            
            

            
        </div>
                    {/*  */}

                </div>
                <div className="hidden lg:block lg:w-1/4 box-border px-4"><RightSidebar/></div>
            </div> 
             
        </>
    )
}

export default PostsFeedPage
