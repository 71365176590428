import React, { useState, useEffect } from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Components/Navbar/Navbar'
import Photo from '../Components/PostPhoto/PostPhoto'
import MultiselectDropdown from '../Components/Dropdown/MultiselectDropdown'
import {IoReturnDownBackOutline} from 'react-icons/io5'
import { postCreateAction } from '../actions/post'
import axios from 'axios'
import { baseURL } from '../api'

function CreatePostPage() {
    const[heading,setHeading] = useState("")
    const[description,setDescription] = useState("")
    const [image, setImage] = useState('https://png.pngtree.com/element_our/20190601/ourmid/pngtree-file-upload-icon-image_1344465.jpg')
    const [userAvatar, setUserAvatar] = useState();
    const [tags,setTags] = useState([])
    
  

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {postsCreate} = useSelector(state => state.post);

    const submitHandler = (e) => {
        e.preventDefault()
        try {
            let formdata = new FormData();
            formdata.append('heading', heading)
            formdata.append('description', description)
            formdata.append('post_image', userAvatar)
            if(userAvatar){
                formdata.append('post_image', userAvatar) 
            }
            if(tags){
                formdata.append('tags',tags)
                // tags = []
            }
            dispatch(postCreateAction(formdata,navigate))
        } catch (error) {
        }

    }   
    const onImageChange = (e) => {
        setUserAvatar(e.target.files[0])
        setImage(URL.createObjectURL(e.target.files[0]))
    }
      
    

    return (
        <>
            <Navbar/>
            <div className="flex bg-background-color font-general overflow-hidden">
                
                <div className="container md:mx-auto pt-32 w-full">
                    <div className="md:box-content xl:w-10/12 p-4 bg-nav-color text-white rounded-2xl mb-20 font-general shadow-2xl mx-2 xl:mx-auto">
                        <h1 className="mt-2 mb-4 text-5xl">Create Post</h1>
                    <div class="md:grid md:grid-cols-2 w-full">
                        <div className="my-8">
                            {/* <Photo/> */}
                            <input className='mt-10 m-auto' type="file" accept="image/*" onChange={onImageChange}/>
                                    <img src={image} className='rounded-3xl w-80 h-80 m-auto mt-10 object-cover'></img>
                                
                            <div>
                   
                </div>
                        </div>
                        <div className="col-span-1 mt-20 md:mt-0 w-full">
                            <div className="w-full">
                            <p className="text-left px-8 py-2">Title</p>
                            <input type="text" placeholder="Title of Post" onChange={(e) => {setHeading(e.target.value)}} className="px-8 py-3 placeholder-blueGray-300 relative text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full md:w-11/12" />
                            </div>
                            <p className="text-left px-8 py-2">Description</p>
                            <textarea rows="10" placeholder="Description" onChange={(e) => {setDescription(e.target.value)}} className="px-8 py-3 placeholder-blueGray-300 relative text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full md:w-11/12" />

                            <div className="flex justify-center items-center w-full">                       
                            <MultiselectDropdown setTags={setTags}/>
                            </div>
                        </div>
                        
                    </div> 
                    <div className="flex align-middle justify-center">
                            <Link to="/"><button type="submit" onClick={submitHandler} className="bg-background-color text-white block px-3 py-2 mt-6 w-40 rounded-md text-base font-medium">
                                Post
                            </button></Link>
                    </div>
                    </div>
                        <Link to="/posts"><div className="fixed bottom-4 right-4 h-10 w-10 bg-white z-50 border-2 border-black rounded-md lg:w-max flex justify-center items-center lg:p-2">
                        <span className="hidden lg:block text-lg mr-2">Back</span>
                        <IoReturnDownBackOutline className="h-full w-full"/>
                        </div></Link>
                </div>

            </div> 
        </>
    )
}

export default CreatePostPage
