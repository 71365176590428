import { auth } from '../types';

const INITIAL_STATE = {
    isGetOTPButtonLoading : false,
    isLoginButtonLoading : false,
    isUserDetailsLoading : false,

    userEmail : null,
    AlumniHubUserAuth : null,
    user : null,

    checkUserError : null,
    checkOtpError : null,
    userDetailError : null,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case auth.SET_USER_EMAIL:
            return {
                ...state,
                userEmail : action.payload
            }
        case auth.CHECK_USER_REQUEST:
            return {
                ...state,
                isGetOTPButtonLoading: action.payload
            }
        case auth.CHECK_USER_SUCCESS:
            return {
                ...state,
                AlumniHubUserAuth : action.payload
            }

        case auth.CHECK_USER_FAILURE:
            return{
                ...state,
                checkUserError : action.payload
            }
        case auth.CHECK_OTP_REQUEST:
            return {
                ...state,
                isLoginButtonLoading: action.payload
            }
        case auth.CHECK_OTP_SUCCESS:
            return {
                ...state,
                user : action.payload
            }

        case auth.CHECK_OTP_FAILURE:
            return{
                ...state,
                checkOtpError : action.payload.message
            }
        case auth.USER_DETAILS_REQUEST:
            return {
                ...state,
                isUserDetailsLoading: action.payload
            }
        case auth.USER_DETAILS_SUCCESS:
            return {
                ...state,
                user : action.payload
            }

        case auth.USER_DETAILS_FAILURE:
            return{
                ...state,
                userDetailError : action.payload.message
            }
        case auth.USER_LOGOUT_REQUEST:
                return {
                    ...state,
                    isUserLogoutLoading: action.payload
                }
        case auth.USER_LOGOUT_SUCCESS:
                return {
    
                }
    
        case auth.USER_LOGOUT_FAILURE:
                return{
                    ...state,
                    userLogoutError : action.payload.message
                }
        case auth.USER_UPDATE_PROFILE_RESET:
            return{}
        case auth.USER_DETAILS_REMOVE:
                return{ }

        default:
            return state
    }
}
