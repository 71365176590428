import React from 'react'
import { FaFacebookSquare , FaInstagramSquare , FaLinkedin , FaYoutube , FaGlobe} from 'react-icons/fa';
import { Link } from 'react-router-dom';


function Footer() {
    return (
        <div >
            <div className="flex justify-center w-full flex-col bg-wave bg-no-repeat bg-cover h-sm md:h-md lg:h-lg xl:h-xl">
                <div className="font-bold text-xl md:text-3xl font-general py-4 mt-32 ">Connect with us here!</div>
                <ul className="flex justify-center w-full">
                <a href="https://www.facebook.com/ISAVESITOfficial/" target="_blank"><li className="p-4 md:px-8 text-3xl md:text-5xl transform hover:-translate-y-4 cursor-pointer duration-500"><FaFacebookSquare/></li></a>
                <a href="https://www.instagram.com/isa_vesit/" target="_blank"><li className="p-4 md:px-8 text-3xl md:text-5xl transform hover:-translate-y-4 cursor-pointer duration-500"><FaInstagramSquare/></li></a>
                <a href="https://www.linkedin.com/company/isa-vesit" target="_blank"><li className="p-4 md:px-8 text-3xl md:text-5xl transform hover:-translate-y-4 cursor-pointer duration-500"><FaLinkedin/></li></a>
                <a href="https://www.youtube.com/c/ISAVESIT" target="_blank"><li className="p-4 md:px-8 text-3xl md:text-5xl transform hover:-translate-y-4 cursor-pointer duration-500"><FaYoutube/></li></a>
                <a href="https://www.isavesit.org.in/" target="_blank"><li className="p-4 md:px-8 text-3xl md:text-5xl transform hover:-translate-y-4 cursor-pointer duration-500"><FaGlobe/></li></a>
                </ul>
                <ul className="grid grid-cols-3 md:flex justify-center w-full font-bold text-xl md:text-3xl font-general py-4">
                <a href="#"><li className="p-4 md:px-8 transform hover:-translate-y-4 cursor-pointer duration-500">Home</li></a>
                <a href="https://www.isavesit.org.in/" target="_blank"><li className="p-4 md:px-8 transform hover:-translate-y-4 cursor-pointer duration-500">About</li></a>
                <a href="https://www.isavesit.org.in/initiatives/" target="_blank"><li className="p-4 md:px-8 transform hover:-translate-y-4 cursor-pointer duration-500">Initiatives</li></a>
                <a href="https://www.isavesit.org.in/council/" target="_blank"><li className="p-4 md:px-8 transform hover:-translate-y-4 cursor-pointer duration-500">Team</li></a>
                <a href="https://www.isavesit.org.in/contactUs/" target="_blank"><li className="p-4 md:px-8 transform hover:-translate-y-4 cursor-pointer duration-500">Contact</li></a>
                </ul>
                <div className="font-bold text-sm font-general">© 2022 ISA-VESIT, Inc. All rights reserved.</div>
            </div>            
        </div>
    )
}

export default Footer
