import React,{useEffect} from 'react'
import {FaHandPointRight} from 'react-icons/fa'
import {useDispatch , useSelector} from 'react-redux'
import { Link } from 'react-router-dom';
import { quickLinksAction } from '../../actions/post';



function RightSidebar() {

    const dispatch = useDispatch();

    const {quickLinks} = useSelector(state => state.post);
    useEffect(() => {
        
        dispatch(quickLinksAction());
        
    },[] )

    return (
        <div className="flex flex-col justify-between items-center rounded-xl shadow-xl mt-40 sticky top-40 bg-nav-color text-white">
            <div className="flex justify-between items-center mb-5 mt-2 text-xl">Quick Links</div>
            {
                quickLinks?.map((l) => {
                    return(<a className="" href={l?.link} target="_blank">
                        <div className="flex justify-around items-center relative mb-4 hover:bg-hover-dark rounded-xl py-2 px-4 cursor-pointer w-full" >
                        <FaHandPointRight className="h-6 w-6 mx-2"/>
                        <p className="hover:underline cursor-pointer ">{l?.text}</p>
                        {/* <div className="absolute bottom-2 left-10 h-3 w-3 rounded-full animate-pulse"></div> */}
                    </div></a>
                    )
                })
            }
            
        </div>
    )
}

export default RightSidebar
