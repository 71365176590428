import {HashRouter as Router , Routes , Route} from "react-router-dom"
import './App.css';

import DetailPostPage from './Pages/DetailPostPage';
import PostsFeedPage from './Pages/PostsFeedPage';
import CreatePostPage from './Pages/CreatePostPage';
import UserProfilePage from './Pages/UserProfilePage';
import EditProfilePage from './Pages/EditProfilePage';
import LandingPage from './Pages/LandingPage';
import EmailPage from './Pages/EmailPage';
import OtpPage from './Pages/OtpPage';
import UserSavedPostsFeedPage from "./Pages/UserSavedPostsFeedPage";
import EditPostPage from "./Pages/EditPostPage";
import TagsPostFeedPage from "./Pages/TagsPostFeedPage";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoutes from "./protectedRoutes/ProtectedRoutes";

function App() {
  return (
    <div className="App ">
      <ToastContainer newestOnTop toastStyle={{ border: "5px solid white" , background:"#03353E" ,color:"white"}} />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage/>}></Route>
          <Route path="/email" element={<EmailPage/>}></Route>
          <Route path="/otp" element={<OtpPage/>}></Route>
          <Route element={<ProtectedRoutes/>}>
          <Route path="/post/:id" element={<DetailPostPage/>}></Route>
          <Route path="/createPost" element={<CreatePostPage/>}></Route>
          <Route path="/editProfile" element={<EditProfilePage/>}></Route>
          <Route path="/editPost/:id" element={<EditPostPage/>}></Route>
          <Route path="/posts" element={<PostsFeedPage/>}></Route>
          <Route path="/savedPosts" element={<UserSavedPostsFeedPage/>}></Route>
          <Route path="/user/:id" element={<UserProfilePage/>}></Route>
          <Route path="/tags/:id" element={<TagsPostFeedPage/>}></Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
