import React from 'react'

const Loader = ({ thickness='3', color='#F48A21', size='25', speed='1' }) => {

    const borderTop = thickness + 'px solid ' + color
    const width = size + 'px'
    const height = size + 'px'
    const animation = 'spin ' + speed + 's linear infinite'

    //what if this value is not passed?

    const loaderStyle = { 
    borderRadius: '50%',
    borderTop,
    width,
    height,
    animation,
    }
    

    return (
        <center>
            <div style={loaderStyle}>

            </div>
        </center>
    )
}

export default Loader
