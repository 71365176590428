import { post } from '../types'
import axios from 'axios'
import { baseURL } from '../api'
import {toast} from 'react-toastify';


// actions without api call

// actions with api call

export const postListAction = () => async (dispatch) => {
    try{
        dispatch({type: post.POST_LIST_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/postList/`,config)
        if(data.status === 1){
            dispatch({type:post.POST_LIST_SUCCESS, payload:data.post})
            dispatch({type: post.POST_LIST_REQUEST, payload:false})
        }else if(data.status === 0){
            dispatch({type:post.POST_LIST_FAILURE, payload:data})
            dispatch({type: post.POST_LIST_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.POST_LIST_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.POST_LIST_REQUEST, payload:false})
    }
}

export const postDetailAction = (id) => async (dispatch) => {
    try{
        dispatch({type: post.POST_DETAILS_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/postDetail/${id}`,config)
        if(data.status === 1){
        
            dispatch({type:post.POST_DETAILS_SUCCESS, payload:data.post})
            dispatch({type: post.POST_DETAILS_REQUEST, payload:false})

        }else if(data.status === 0){
            dispatch({type:post.POST_DETAILS_FAILURE, payload:data})
            dispatch({type: post.POST_DETAILS_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.POST_DETAILS_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.POST_DETAILS_REQUEST, payload:false})
    }
}

export const postSaveAction = (id) => async (dispatch) => {
    try{
        dispatch({type: post.SAVE_POST_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/savePost/${id}`,config)
        if (data.status === 2){
            dispatch({type:post.SAVE_POST_SUCCESS, payload:data.post})
            dispatch({type: post.SAVE_POST_REQUEST, payload:false})
            toast.success('Post Unsaved Successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
        else if(data.status === 1){
            dispatch({type:post.SAVE_POST_SUCCESS, payload:data.post})
            dispatch({type: post.SAVE_POST_REQUEST, payload:false})
            toast.success('Post Saved Successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });

        }else if(data.status === 0){
            dispatch({type:post.SAVE_POST_FAILURE, payload:data})
            dispatch({type: post.SAVE_POST_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.SAVE_POST_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.SAVE_POST_REQUEST, payload:false})
        
    }
}

export const postCreateAction = (postCreate,navigate) => async (dispatch) => {
    try{
        dispatch({type: post.POST_CREATE_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.post(`${baseURL}/users/createpost/`,postCreate,config)
        if(data.status === 1){
            dispatch({type:post.POST_CREATE_SUCCESS, payload:data})
            dispatch({type: post.POST_CREATE_REQUEST, payload:false})
            navigate('/posts')
            toast.success(' Posted!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }else if(data.status === 0){
            dispatch({type:post.POST_CREATE_FAILURE, payload:data})
            dispatch({type: post.POST_CREATE_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.POST_CREATE_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.POST_CREATE_REQUEST, payload:false})
    }
}

export const userPostListAction = () => async (dispatch) => {
    try{
        dispatch({type: post.USER_POST_LIST_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/userpostList/`,config)
        if(data.status === 1){
            dispatch({type:post.USER_POST_LIST_SUCCESS, payload:data.post})
            dispatch({type: post.USER_POST_LIST_REQUEST, payload:false})
        }else if(data.status === 0){
            dispatch({type:post.USER_POST_LIST_FAILURE, payload:data})
            dispatch({type: post.USER_POST_LIST_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.USER_POST_LIST_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.USER_POST_LIST_REQUEST, payload:false})
    }
}

export const userPostUpdateAction = (detailPost,navigate) => async (dispatch) => {
    try{
        
        dispatch({type: post.POST_DETAILS_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-Type': 'multipart/form-data',Authorization : `Bearer ${access}`}}
        const pid = detailPost.get('id')
        const {data} = await axios.put(`${baseURL}/users/updatePost/${pid}/`,detailPost,config)
        navigate('/posts')
        toast.success('Post Updated', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        if(data.status === 1){
            dispatch({type:post.POST_DETAILS_SUCCESS, payload:data.post})
            dispatch({type: post.POST_DETAILS_REQUEST, payload:false})
            
        }else if(data.status === 0){
            dispatch({type:post.POST_DETAILS_FAILURE, payload:data})
            dispatch({type: post.POST_DETAILS_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.POST_DETAILS_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.POST_DETAILS_REQUEST, payload:false})
    }
}

export const userPostImageUpdateAction = (formData,postDetail) => async (dispatch) => {
    try{
        dispatch({type: post.POST_DETAILS_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'multipart/form-data',Authorization : `Bearer ${access}`}}
        const {data} = await axios.put(`${baseURL}/users/updatePost/${postDetail.id}/`,formData,postDetail.id,config)
        if(data.status === 1){
            dispatch({type:post.POST_DETAILS_SUCCESS, payload:data.post})
            dispatch({type: post.POST_DETAILS_REQUEST, payload:false})
            // navigate('/posts')
        }else if(data.status === 0){
            dispatch({type:post.POST_DETAILS_FAILURE, payload:data})
            dispatch({type: post.POST_DETAILS_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.POST_DETAILS_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.POST_DETAILS_REQUEST, payload:false})
    }
}

export const userPostDeleteAction = (id,navigate) => async (dispatch) => {
    try{
        dispatch({type: post.USER_POST_DELETE_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/deletePost/${id}/`,config)

        if(data.status === 1){
            dispatch({type:post.USER_POST_DELETE_SUCCESS, payload:data.post})
            dispatch({type: post.USER_POST_DELETE_REQUEST, payload:false})
            navigate('/posts')
            toast.warning('Post Deleted!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }else if(data.status === 0){
            dispatch({type:post.USER_POST_DELETE_FAILURE, payload:data})
            dispatch({type: post.USER_POST_DELETE_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.USER_POST_DELETE_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.USER_POST_DELETE_REQUEST, payload:false})
    }
}

export const userSavedPostsAction = () => async (dispatch) => {
    try{
        dispatch({type: post.USER_SAVED_POSTS_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/savedpost/`,config)

        if(data.status === 1){
            dispatch({type:post.USER_SAVED_POSTS_SUCCESS, payload:data.savedpost})
            dispatch({type: post.USER_SAVED_POSTS_REQUEST, payload:false})

        }else if(data.status === 0){
            dispatch({type:post.USER_SAVED_POSTS_FAILURE, payload:data})
            dispatch({type: post.USER_SAVED_POSTS_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.USER_SAVED_POSTS_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.USER_SAVED_POSTS_REQUEST, payload:false})
    }
}

export const userProfileAction = (id) => async (dispatch) => {
    try{
        dispatch({type: post.USER_PROFILE_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/viewuser/${id}/`,config)

        if(data.status === 1){
            dispatch({type:post.USER_PROFILE_SUCCESS, payload:data})
            dispatch({type: post.USER_PROFILE_REQUEST, payload:false})

        }else if(data.status === 0){
            dispatch({type:post.USER_PROFILE_FAILURE, payload:data})
            dispatch({type: post.USER_PROFILE_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.USER_PROFILE_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.USER_PROFILE_REQUEST, payload:false})
    }
}

export const postTagsAction = () => async (dispatch) => {
    try{
        dispatch({type: post.GET_POST_TAGS_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/tags/`,config)

        if(data.status === 1){
            dispatch({type:post.GET_POST_TAGS_SUCCESS, payload:data.tags})
            dispatch({type: post.GET_POST_TAGS_REQUEST, payload:false})

        }else if(data.status === 0){
            dispatch({type:post.GET_POST_TAGS_FAILURE, payload:data})
            dispatch({type: post.GET_POST_TAGS_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.GET_POST_TAGS_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.GET_POST_TAGS_REQUEST, payload:false})
    }
}

export const quickLinksAction = () => async (dispatch) => {
    try{
        dispatch({type: post.GET_QUICKLINKS_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/quicklinks/`,config)

        if(data.status === 1){
            dispatch({type:post.GET_QUICKLINKS_SUCCESS, payload:data.link})
            dispatch({type: post.GET_QUICKLINKS_REQUEST, payload:false})

        }else if(data.status === 0){
            dispatch({type:post.GET_QUICKLINKS_FAILURE, payload:data})
            dispatch({type: post.GET_QUICKLINKS_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.GET_QUICKLINKS_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.GET_QUICKLINKS_REQUEST, payload:false})
    }
}

export const tagPostAction = (id,navigate) => async (dispatch) => {
    try{
        dispatch({type: post.GET_TAG_POST_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/getpostlisttags/${id}`,config)

        if(data.status === 1){
            dispatch({type:post.GET_TAG_POST_SUCCESS, payload:data.posttags})
            dispatch({type: post.GET_TAG_POST_REQUEST, payload:false})
            
        }else if(data.status === 0){
            dispatch({type:post.GET_TAG_POST_FAILURE, payload:data})
            dispatch({type: post.GET_TAG_POST_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: post.GET_TAG_POST_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: post.GET_TAG_POST_REQUEST, payload:false})
    }
}


export const removeSelectedPost = () => async (dispatch) => {
    dispatch({type: post.POST_DETAILS_REMOVE})
    
}