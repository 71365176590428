import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Link, useParams,useNavigate} from 'react-router-dom'
import LikeBtn from './LikeBtn'
import Sharebtn from './Sharebtn'
import Tag from './Tag'
import SaveBtn from './SaveBtn'
import {BiShareAlt} from 'react-icons/bi'
import {FaEdit} from 'react-icons/fa'
import { postSaveAction } from '../actions/post'
import moment from 'moment'
import { userDetailsAction} from '../actions/auth'


function Post({key,name,title,message,email,post_id,timestamp,profilePic,postImage,tags,isAlumni,authorProfile,author_id}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {user} = useSelector(state => state.auth);
    useEffect(() => {
        dispatch(userDetailsAction())     
        
    },[])

    const dateTime = moment(timestamp).startOf('second').fromNow(); 


    const [like,setLike] = useState(false)
    const [sliceText,setSliceText] = useState(true)
    const [showModal, setShowModal] = React.useState(false);

    const LikeHandler = () => {
        setLike(!like)
    }
    const toggleSlice = () => {
        setSliceText(!sliceText)
    }
    
    var slicedMessage = sliceText ?  message.slice(0,200) : message

    return (
        <div className="flex flex-col">
            <div className="p-5 bg-nav-color text-white mt-5 rounded-t-2xl shadow-2xl ">
                <div className="block lg:flex justify-between ">
                    <Link to={`/user/${author_id}`}><div className="flex items-center space-x-2 cursor-pointer z-10">
                        <img className= {`rounded-full w-10 h-10 border-2 z-10 ${isAlumni ? "border-blue-400" : "border-red-400" } `}  src={profilePic} alt="profilePic"></img>
                        <div>
                            <p className="font-medium">{name}</p>
                            <p className="text-xs">{dateTime}</p>
                        </div>
                    </div></Link>
                    
                    <div className="mt-2 lg:mt-0 flex justify-center items-center flex-wrap">{
                        tags.map((tag) => (
                            <div>{<Tag value={tag.tags}/>}</div>
                        ))
                    }
                    </div>
                </div>
                <div className="flex justify-center font-bold text-xl py-2 px-2 break-all">{title}</div>
                <p className="pt-4 text-left break-all">{slicedMessage}
                <Link to={`/post/${post_id}`}><span onClick={toggleSlice} className="text-gray-400 cursor-pointer inline z-10"> 
                    {sliceText ? " ...Read More" : " ...Read Less"}
                </span></Link></p>
                

            </div>
        {
            postImage && (
                <div className="flex justify-center items-center relative h-56 md:h-96 bg-nav-color ">
                    <img src={postImage} alt="postImg" className="object-cover h-full w-auto" onClick={() => setShowModal(true)}></img>
                    
                    {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-xl">
              {/*content*/}
              <div className="border-2 rounded-lg shadow-lg relative flex flex-col w-full bg-nav-color outline-none focus:outline-none">
                {/*body*/}
                <img src={postImage}/>
                {/*footer*/}
                <div className="flex items-center justify-end p-6  rounded-b">
                  <button
                    className="text-white background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null} 
                </div>
            )
        }

        {/* post footer */}

        <div className="flex justify-between items-center rounded-b-2xl bg-white border-t h-4">
            
        {/* <div onClick={LikeHandler} className="flex justify-center items-center border-2 w-full rounded-b-xl py-2 cursor-pointer z-10">
                        <LikeBtn like={like}/>
                        <p className="text-xs sm:text-base ">Like</p>
                    </div> */}

            {/* <div onClick={SaveHandler} className="flex justify-center items-center border-2 w-full py-2 cursor-pointer ">
                <SaveBtn save={save}/>
                <p className="text-xs sm:text-base">{save? "Save":"Unsave"}</p>
            </div> */}
            {/* <div onClick={openModal} className="flex justify-center items-center border-2 w-full rounded-br-xl py-2 cursor-pointer">
                <BiShareAlt className="h-4" />
                <p className="text-xs sm:text-base "><Sharebtn isOpen={isOpen} setIsOpen={setIsOpen} /></p>
            </div> */}
        </div>

        </div>
    )
}

export default Post
