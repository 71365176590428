import React,{useState,useEffect} from 'react'
import { Disclosure ,Transition } from '@headlessui/react'
import MyToggle from './switch'
import {AiOutlineDown} from 'react-icons/ai'
import {useDispatch,useSelector} from 'react-redux'
import { postTagsAction } from '../../actions/post'
import { Link, useNavigate} from 'react-router-dom'
 


function SidebarDropdown() {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isPostTagsLoading,postTags,postTagsError} = useSelector(state => state.post);
  useEffect(() => {
      dispatch(postTagsAction());

  },[] )

  

  

    return (
        <>
          <Disclosure>
      <Disclosure.Button className="p-5 w-full h-full hover:bg-hover-dark flex justify-center items-center">
        Tags 
      </Disclosure.Button>
      <Transition
        enter="transition duration-200 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-100 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
      <Disclosure.Panel className="text-white flex justify-center flex-col items-center bg-nav-color">
       
        {
          postTags?.map((t)=>{
            return(
              <div className="flex justify-start w-full p-1 m-1 hover:bg-hover-dark" onClick={() => window.location.reload(false)}>
              <Link to={`/tags/${t?.id}`} className="w-full" >
        <p>{t?.tags}</p>
        </Link></div>
            )
          })
        }
        <div>
          {/* <Disclosure.Button className="py-1 px-4 mb-2 border-2 border-white rounded-xl hover:bg-hover-dark">Apply</Disclosure.Button> */}
          </div>
        
      </Disclosure.Panel>
      </Transition>
    </Disclosure>
  
        </>
    )
}

export default SidebarDropdown
