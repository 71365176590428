import React, { useState, useEffect } from 'react'
import Multiselect from 'multiselect-react-dropdown';
import './MultiselectDropdown.css'
import {useDispatch,useSelector} from 'react-redux'
import Tag from '../Tag';
import { postTagsAction,postDetailAction } from '../../actions/post';

function MultiselectDropdown({setTags,id}) {

  const dispatch = useDispatch();
  const {isPostTagsLoading,postTags,postTagsError,postDetail} = useSelector(state => state.post);
  const [selected, setSelected] = useState([]);
  // const [tags,setTags] = useState([])
  var temp = postTags?.map((t) => {
    return(
      {"Tag" : t.id,
       "key": t.tags }
    )
  })
  if(id){
    var stag = postDetail?.tags?.map((x) => {
      return(
        {
          "Tag" :x.id,
          "key" :x.tags
        }
      )
    })
  }else{
    var stag = []
  }
  
  
  useEffect(() => {
      dispatch(postTagsAction());
      dispatch(postDetailAction(id));

  },[] )

    return (
        <>
            <Multiselect
              id="css_custom"
  displayValue="key"
  onKeyPressFn={function noRefCheck(){
    
  }}
  onRemove={function noRefCheck(e){
    setTags(e.map((t)=>{return t.Tag}))
    
  }}
  onSearch={function noRefCheck(){}}
  onSelect={function noRefCheck(e){
    setTags(e.map((t)=>{return t.Tag}))
    console.log(e.map((t)=>{return t.Tag}))
    
  }}
  value={selected}
  
  options={
    temp
  }
  selectedValues={
    stag
  }

  placeholder="Select Tags"
  style={{
    chips: {
      background: '#03353E'
    },
    searchBox: {
      border: '2px solid white',
      'border-radius': '0.25rem',
      background:"white",
      caretColor: "#03353E",
      maxWidth:"100%"
    },
    option:{
        color:"#03353E",
        background:"white"
    },
    multiselectContainer: { 
        display:"flex",
        justifyContent:"center",
        marginTop:"2rem",
        width:"100%",
      }
    
  }}

/>

        </>
    )
}

export default MultiselectDropdown
