import { combineReducers } from 'redux'
import auth from './auth'
import post from './post'

const appReducer = combineReducers({
     auth,
     post,
})


const reducer = (state, action) => {
    // if (action.type === RESET_REDUX_STORE) {
    //   return appReducer({}, action)
    // }
  
    return appReducer(state, action)
  }

export default reducer