import React, {useEffect } from 'react'
import { Link,useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Components/Navbar/Navbar'
import { FaLinkedin , FaGithub ,FaUserEdit } from 'react-icons/fa';
import {IoReturnDownBackOutline} from 'react-icons/io5'
import { userDetailsAction,removeUser } from '../actions/auth'
import { userPostListAction , userProfileAction } from '../actions/post'
import Profile from '../Components/Profile';
import Loader from '../Components/Loader';
import Post from '../Components/Post'



function UserProfilePage() {

    const dispatch = useDispatch()
    const user_id = useParams();
    
    const {user} = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(userDetailsAction())     
        
    },[dispatch])

    
    const {isUserProfileLoading,userProfile,userProfileError} = useSelector(state => state.post);

    useEffect(() => {
        dispatch(userProfileAction(user_id.id));
        return () =>{
            dispatch(removeUser());
          }

    },[] )
  

    return (
        <>
            <Navbar/>
            <div className="flex flex-col bg-background-color font-general w-full">
                
        
                <div className="py-10 justify-center items-center mt-20 mx-2">
                    <Profile author={userProfile?.profile}/>
                </div>
                <div className="w-full lg:w-1/2 box-border px-4 mx-auto">
                    <div className="mt-4 mb-4">
                    {isUserProfileLoading ? <Loader/>
                : userProfileError ? <h1>Error</h1>
                    : <div>
                        {
                            (userProfile?.post)?.length > 0 &&
                            <div className="text-4xl font-bold sticky justify-center items-center w-full ">{userProfile?.profile?.user?.first_name}'s Posts</div>
                        }
                    
                        {userProfile?.post?.slice(0).reverse().map(p => (

                            <Post
                            key={p.id}
                            post_id ={p?.id}
                        
                            name={`${p.user.first_name} ${p.user.last_name}`}
                            title={p.heading}
                            message = {p.description}
                            email={p.user.email}
                            timestamp={p.created_at}
                            profilePic={`https://alumnihub.isavesit.org.in${p.profile.profile_image}`}

                            postImage={p.post_image ? `https://alumnihub.isavesit.org.in${p.post_image}` : null}
                            tags={
                                p.tags
                            }
                            isAlumni={true}
                            authorProfile = {p.profile}
                            author_id = {p?.user?.id}
                            />
                        
                        ))}
                            </div>
                         } </div>
                    </div>
        

            </div>
            <Link to="/posts"><div className="fixed bottom-4 right-4 h-10 w-10 bg-white z-50 border-2 border-black rounded-md lg:w-max flex justify-center items-center lg:p-2">
                <span className="hidden lg:block text-lg mr-2">Back</span>
                <IoReturnDownBackOutline className="h-full w-full"/>
                </div>
            </Link>
        </>
    )
}

export default UserProfilePage
