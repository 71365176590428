export const POST_LIST_REQUEST = 'POST_LIST_REQUEST'
export const POST_LIST_SUCCESS = 'POST_LIST_SUCCESS'
export const POST_LIST_FAILURE = 'POST_LIST_FAILURE'

export const SAVE_POST_REQUEST = 'SAVE_POST_REQUEST'
export const SAVE_POST_SUCCESS = 'SAVE_POST_SUCCESS'
export const SAVE_POST_FAILURE = 'SAVE_POST_FAILURE'

export const POST_DETAILS_REQUEST = 'POST_DETAILS_REQUEST'
export const POST_DETAILS_SUCCESS = 'POST_DETAILS_SUCCESS'
export const POST_DETAILS_FAILURE = 'POST_DETAILS_FAILURE'
export const POST_DETAILS_REMOVE = 'POST_DETAILS_REMOVE'

export const POST_CREATE_REQUEST = 'POST_CREATE_REQUEST'
export const POST_CREATE_SUCCESS = 'POST_CREATE_SUCCESS'
export const POST_CREATE_FAILURE = 'POST_CREATE_FAILURE'

export const USER_POST_LIST_REQUEST = 'USER_POST_LIST_REQUEST'
export const USER_POST_LIST_SUCCESS = 'USER_POST_LIST_SUCCESS'
export const USER_POST_LIST_FAILURE = 'USER_POST_LIST_FAILURE'

export const USER_POST_UPDATE_REQUEST = 'USER_POST_UPDATE_REQUEST'
export const USER_POST_UPDATE_SUCCESS = 'USER_POST_UPDATE_SUCCESS'
export const USER_POST_UPDATE_FAILURE = 'USER_POST_UPDATE_FAILURE'

export const USER_UPDATE_POST_RESET = 'USER_UPDATE_POST_RESET'

export const USER_POST_DELETE_REQUEST = 'USER_POST_DELETE_REQUEST'
export const USER_POST_DELETE_SUCCESS = 'USER_POST_DELETE_SUCCESS'
export const USER_POST_DELETE_FAILURE = 'USER_POST_DELETE_FAILURE'

export const USER_SAVED_POSTS_REQUEST = 'USER_SAVED_POSTS_REQUEST'
export const USER_SAVED_POSTS_SUCCESS = 'USER_SAVED_POSTS_SUCCESS'
export const USER_SAVED_POSTS_FAILURE = 'USER_SAVED_POSTS_FAILURE'

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE'

export const GET_POST_TAGS_REQUEST = 'GET_POST_TAGS_REQUEST'
export const GET_POST_TAGS_SUCCESS = 'GET_POST_TAGS_SUCCESS'
export const GET_POST_TAGS_FAILURE = 'GET_POST_TAGS_FAILURE'

export const GET_QUICKLINKS_REQUEST = 'GET_QUICKLINKS_REQUEST'
export const GET_QUICKLINKS_SUCCESS = 'GET_QUICKLINKS_SUCCESS'
export const GET_QUICKLINKS_FAILURE = 'GET_QUICKLINKS_FAILURE'

export const GET_TAG_POST_REQUEST = 'GET_TAG_POST_REQUEST'
export const GET_TAG_POST_SUCCESS = 'GET_TAG_POST_SUCCESS'
export const GET_TAG_POST_FAILURE = 'GET_TAG_POST_FAILURE'