import React ,{useEffect} from 'react'
import { userDetailsAction } from '../actions/auth'
import {useDispatch,useSelector} from 'react-redux'
import { Outlet } from 'react-router-dom';
import LandingPage from '../Pages/LandingPage';


function ProtectedRoutes() {

  const dispatch = useDispatch()
  const {user} = useSelector(state => state.auth);
    useEffect(() => {
        dispatch(userDetailsAction())     
        
    },[dispatch])

  return (

    user ? <Outlet/> : <LandingPage/>

  )
}

export default ProtectedRoutes