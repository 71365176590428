import React, { useState, useEffect } from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Components/Navbar/Navbar'
import Photo from '../Components/PostPhoto/PostPhoto'
import MultiselectDropdown from '../Components/Dropdown/MultiselectDropdown'
import {IoReturnDownBackOutline} from 'react-icons/io5'
import { USER_UPDATE_POST_RESET } from '../types/post'
import { userPostUpdateAction,postDetailAction, userPostImageUpdateAction } from '../actions/post'


function EditPostPage() {
    const[heading,setHeading] = useState("")
    const[description,setDescription] = useState("")
    const [userAvatar, setUserAvatar] = useState();
    const [image, setImage] = useState();
    const [tags  , setTags] = useState([])
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const post_id = useParams()

    const {postDetail,success,userPosts} = useSelector(state => state.post);
    const {user} = useSelector(state => state.auth);
    
    useEffect(() => {
       
        if (!user || !user?.user?.email || success) {
            dispatch({ type: USER_UPDATE_POST_RESET })
            dispatch(postDetailAction(post_id.id))    
        } else {
            setHeading(postDetail?.heading)
            setDescription(postDetail?.description)
            if (postDetail?.post_image){
                setImage(`https://alumnihub.isavesit.org.in${postDetail?.post_image}`)
            }else{
                setImage(`https://png.pngtree.com/element_our/20190601/ourmid/pngtree-file-upload-icon-image_1344465.jpg`)
            }
        }
    
}, [dispatch,user,postDetail,success])
const submitHandler = (e) => {
    e.preventDefault()
    try {
        let formdata = new FormData();
        formdata.append('id', postDetail?.id)
        formdata.append('heading', heading)
        formdata.append('description', description)
        
        if(tags){
            formdata.append('tags',tags)
            // tags = []
        }
        
        if(userAvatar){
            formdata.append('post_image', userAvatar) 
        }
        dispatch(userPostUpdateAction(formdata,navigate))
    } catch (error) {
    }
      
}
const onImageChange = (e) => {
    setUserAvatar(e.target.files[0])
    setImage(URL.createObjectURL(e.target.files[0]))
}
  return (
    <>
    <Navbar/>
            <div className="flex bg-background-color font-general overflow-hidden">
                
                <div className="container md:mx-auto pt-32">
                    <div className="md:box-content xl:w-10/12 p-4 bg-nav-color text-white rounded-2xl mb-20 font-general shadow-2xl mx-2 xl:mx-auto">
                        <h1 className="mt-2 mb-4 text-5xl">Edit Post</h1>
                    <div class="md:grid md:grid-flow-col">
                        <div className="my-8">
                            
                            <input className='mt-10 m-auto' type="file" accept="image/*" onChange={onImageChange}/>
                           
                                    <img src={image} className='rounded-3xl w-80 h-80 m-auto mt-10 object-cover'></img>
                            
                        </div>
                        <div className="col-span-1 mt-20 md:mt-0">
                            <p className="text-left px-8 py-2">Title</p>
                            <input type="text" placeholder="Title of Post" value={heading} onChange={(e) => {setHeading(e.target.value)}} className="px-8 py-3 placeholder-blueGray-300 relative text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full md:w-11/12" />

                            <p className="text-left px-8 py-2">Description</p>
                            <textarea rows="10" placeholder="Description" value={description} onChange={(e) => {setDescription(e.target.value)}} className="px-8 py-3 placeholder-blueGray-300 relative text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full md:w-11/12" />

                            
                            <div className="flex justify-center items-center">                       
                            <MultiselectDropdown setTags={setTags} id={post_id.id}/>
                            </div>
                        </div>
                        
                    </div> 
                    <div className="flex align-middle justify-center">
                            <Link to="/"><a onClick={submitHandler} className="bg-background-color text-white block px-3 py-2 mt-6 w-40 rounded-md text-base font-medium">
                                Save
                            </a></Link>
                    </div>
                    </div>
                        <Link to="/posts"><div className="fixed bottom-4 right-4 h-10 w-10 bg-white z-50 border-2 border-black rounded-md lg:w-max flex justify-center items-center lg:p-2">
                        <span className="hidden lg:block text-lg mr-2">Back</span>
                        <IoReturnDownBackOutline className="h-full w-full"/>
                        </div></Link>
                </div>

            </div> 
    </>
  )
}

export default EditPostPage