import React, { useState , Fragment } from "react";
import { Transition , Menu} from "@headlessui/react";
import IsaLogo from "../../assets/IsaLogo.png"
import BatmanPic from '../../assets/batman.jpg'
import {Link, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { userLogoutAction } from "../../actions/auth";

function Navbar() {
  
  const [navbar , setNavbar] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const changeNavBG = () => {
    if ((window.scrollY >= 90 && window.screen.width >= 1024) || (window.scrollY >= 75 && (window.screen.width >= 768)) || (window.scrollY >= 45 && (window.screen.width >= 425)) || (window.scrollY >= 45 && window.screen.width < 425 )){
      setNavbar(true)
    }else{
      setNavbar(false)
    }
    
  }

  window.addEventListener("scroll" , changeNavBG)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const {user} = useSelector(state => state.auth);

  const signoutHandler = () =>{
    dispatch(userLogoutAction(navigate))
  }

  return (
    <div>
      <nav className={navbar ? "bg-nav-color h-24 fixed top-0 w-full z-30 duration-1000 shadow-2xl drop-shadow-2xl " : " bg-transparent h-24 fixed top-0 w-full z-30 duration-1000 "}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-24 w-full">
            
          <Link to={`/posts`}><div className="md:block flex-shrink-0 h-20 w-20 ">
                <img
                  className="animate-bounce h-20 w-20"
                  src={IsaLogo}
                  alt="Workflow"
                />
              </div>
              </Link>
              
              <div className={navbar ? "font-heading text-2xl md:text-5xl lg:text-7xl text-white duration-1000 shadow-inner" : "font-heading text-2xl md:text-5xl lg:text-7xl "}>Alumni Hub</div>

              
                <Menu as="div" className="ml-3 relative font-general">
                  <div>
                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-12 w-12 rounded-full"
                        src={`https://alumnihub.isavesit.org.in${user?.profile_image}`}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-nav-color ring-1 text-white ring-white ring-opacity-5 focus:outline-none border-2">
                      <Menu.Item>
                        {({ active }) => (
                          <Link to="/createPost"><a
                            className={classNames(active ? 'bg-hover-dark' : '', 'block px-4 py-2 text-sm text-white')}
                          >
                            Create Post
                          </a></Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link to={`/user/${user?.user?.id}`}><a
                            className={classNames(active ? 'bg-hover-dark' : '', 'block px-4 py-2 text-sm text-white')}
                          >
                            My Profile
                          </a></Link>
                        )}
                      </Menu.Item>
                      
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            
                            className={classNames(active ? 'bg-hover-dark' : '', 'block px-4 py-2 text-sm text-white cursor-pointer')}
                          onClick={signoutHandler}>
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
                
            </div>
            
        </div>

        
      </nav>
    </div>
  );
}

export default Navbar;
