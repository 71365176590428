import React from 'react'

function Tag(tag) {
    return (
        <div className="p-1 m-1 bg-white text-nav-color w-max rounded-lg">
            {tag.value}
        </div>
    )
}

export default Tag
