export const SET_USER_EMAIL = 'SET_USER_EMAIL'

export const CHECK_USER_REQUEST = 'CHECK_USER_REQUEST'
export const CHECK_USER_SUCCESS = 'CHECK_USER_SUCCESS'
export const CHECK_USER_FAILURE = 'CHECK_USER_FAILURE'

export const CHECK_OTP_REQUEST = 'CHECK_OTP_REQUEST'
export const CHECK_OTP_SUCCESS = 'CHECK_OTP_SUCCESS'
export const CHECK_OTP_FAILURE = 'CHECK_OTP_FAILURE'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAILURE = 'USER_DETAILS_FAILURE'
export const USER_DETAILS_REMOVE = 'USER_DETAILS_REMOVE'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE'

export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'


