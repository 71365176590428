import React ,{useEffect} from 'react'
import { userDetailsAction } from '../actions/auth'
import {useDispatch,useSelector} from 'react-redux'
import { Link,useNavigate,useParams } from 'react-router-dom'
import Footer from '../Components/Footer'
import LandingPageNavbar from '../Components/Navbar/LandingPage-navbar'
import Graduation from '../assets/graduation.svg'
import Image2 from '../assets/Image2.svg'
import Image3 from '../assets/Image3.svg'
import Image4 from '../assets/Image4.svg'
import Aos from 'aos';
import 'aos/dist/aos.css'


function LandingPage() {

    const dispatch = useDispatch()
    const {user} = useSelector(state => state.auth);
    const navigate = useNavigate()
    useEffect(() => {
        Aos.init({ duration:2000 })
    },[])
    
      useEffect(() => {
          dispatch(userDetailsAction()) 
          if (user){
            navigate('/posts');
          }    
          
      },[dispatch])
    return (
        <>
            <LandingPageNavbar/>
            <div className="bg-gradient-to-b from-background-color to-white ">
                
                {/*  */}
                <div className="pt-40 lg:pt-15 xl:pt-10">  
            
            <div className="w-full h-full md:w-1/2 md:h-1/2 mx-auto mb-40 lg:mt-0">
               <img src={Graduation} className="w-full h-full"></img>
            </div>
            <div className="md:flex justify-between md:h-96 overflow-x-hidden">
                <div data-aos="fade-right" className=" w-full md:w-1/2 p-4">
                    <img src={Image2} className="w-full h-full"></img>
                </div>
                <div data-aos="fade-left" className="w-full md:w-1/2 p-4 flex justify-center items-center">
                    <div className="w-full lg:w-3/4 xl:w-1/2 h-auto bg-nav-color text-white rounded-2xl p-7 font-general shadow-shadow hover:shadow-none transform hover:-translate-y-2">
                        <span className="p-2 block font-bold text-xl">Welcome to Alumni Hub!</span>
                    The alumni network helps us with an opportunity to build a foundation of support. 
                    ISA-VESIT is what it is today because of the contribution of the alumni.
                     With the great alumni connection that we have, you can interact with them now through this platform, 
                    be it your doubts or any help that you need in your projects or career guidance.
                    </div>
                </div>
            </div>
            <div className="md:flex justify-between md:h-96 overflow-x-hidden">
                <div data-aos="fade-right" className="w-full md:w-1/2 p-4 md:flex justify-center items-center hidden">
                    <div className="w-full lg:w-3/4 xl:w-1/2 h-auto bg-nav-color text-white rounded-2xl p-7 font-general shadow-shadow hover:shadow-none transform hover:-translate-y-2">
                        <span className="p-2 block font-bold text-xl">Why Alumni Hub?</span>
                        VESIT’s alumni network has been the most advantageous for the students. Taking that into consideration, 
                        ISA-VESIT launched this hub, 
                        as a platform for the students to connect and interact with the alumni to get valuable knowledge and guidance.
                    </div>
                </div>
                <div data-aos="fade-left" className=" w-full md:w-1/2 p-4">
                    <img src={Image3} className="w-full h-full"></img>
                </div>
                <div data-aos="fade-right" className="w-full md:w-1/2 p-4 flex justify-center items-center md:hidden">
                    <div className="w-full lg:w-3/4 xl:w-1/2 h-auto bg-nav-color text-white rounded-2xl p-7 font-general shadow-shadow hover:shadow-none transform hover:-translate-y-2">
                        <span className="p-2 block font-bold text-xl">Welcome to Alumni Hub!</span>
                    The alumni network helps us with an opportunity to build a foundation of support. 
                    ISA-VESIT is what it is today because of the contribution of the alumni.
                     With the great alumni connection that we have, you can interact with them now through this platform, 
                    be it your doubts or any help that you need in your projects or career guidance.
                    </div>
                </div>
            </div>
            <div className="md:flex justify-between md:h-96 overflow-x-hidden">
                <div data-aos="fade-right" className=" w-full md:w-1/2 p-4">
                    <img src={Image4} className="w-full h-full"></img>
                </div>
                <div data-aos="fade-left" className="w-full md:w-1/2 p-4 flex justify-center items-center">
                    <div className="w-full lg:w-3/4 xl:w-1/2 h-auto bg-nav-color text-white rounded-2xl p-7 font-general shadow-shadow hover:shadow-none transform hover:-translate-y-2">
                        <span className="p-2 block font-bold text-xl">What is ISA-VESIT?</span>
                        ISA-VESIT is the student section of ISA International that comes under the ISA Maharashtra section.
                         It aims to bridge the gap between the student community and industry by conducting various workshops 
                         and undertaking various initiatives to enlighten
                         the students with technical knowledge and make them impart it on day-to-day life practical applications.
                    </div>
                </div>
            </div>
            
        </div>
                {/*  */}

                <Footer/>
            </div>
        </>
    )
}


export default LandingPage
