import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Link, useParams,useNavigate} from 'react-router-dom'
import DetailPost from '../Components/DetailPost'
import Navbar from '../Components/Navbar/Navbar'
import { postDetailAction } from '../actions/post'
import Dialog from '../Components/Dialog'
import Loader from '../Components/Loader'


function DetailPostPage() {
    const dispatch = useDispatch();
    const post_id = useParams()

    const {postDetail,isPostDetailLoading} = useSelector(state => state.post);
    
    useEffect(() => {
        dispatch(postDetailAction(post_id.id));
        
    },[] )
    
    return (
        <>
            <Navbar/>
            <div className="flex bg-background-color font-general">
                 {
                    isPostDetailLoading ? <Loader/>
                    :
                 
                 <DetailPost
                post_id={`${postDetail?.id}`}
                name={`${postDetail?.user?.first_name} ${postDetail?.user?.last_name}`}
                title={postDetail?.heading}
                message = {postDetail?.description}
                email={postDetail?.user?.email}
                timestamp={postDetail?.created_at}
                profilePic={`https://alumnihub.isavesit.org.in${postDetail?.profile?.profile_image}`}
                postImage={postDetail?.post_image ? `https://alumnihub.isavesit.org.in${postDetail?.post_image}` : null}
                tags={
                    postDetail?.tag
                }
                isAlumni={true}
                github={postDetail?.profile?.github}
                linkedin={postDetail?.profile?.linkedin}
                />
            }
            </div> 
        </>
    )
}

export default DetailPostPage
