import React, { Component } from 'react'
import { FaUpload } from "react-icons/fa";
import {BsCloudArrowUp} from "react-icons/bs"
import './PostPhoto.css'

export default class Photo extends Component {
    state={
        profileImg: "https://png.pngtree.com/element_our/20190601/ourmid/pngtree-file-upload-icon-image_1344465.jpg"
      }
      imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () =>{
          if(reader.readyState === 2){
            this.setState({profileImg: reader.result})
          }
        }
        reader.readAsDataURL(e.target.files[0])
      };
    render() {
        const { profileImg} = this.state
        return (
            <div>
					<div className="post-img-holder py-10 mb-16 ">
						<img src={profileImg} alt="" id="img" className="post-img" />
            <div className="flex align-middle justify-center">
            <input type="file" accept="image/*" name="image-upload" id="input" className="w-60 py-6 md:py-10"  onChange={this.imageHandler} />
            </div>
					</div>	
				</div>
        )
    }
}
