import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Components/Navbar/Navbar'
import Photo from '../Components/ProfilePhoto/ProfilePhoto'
import { IoReturnDownBackOutline } from 'react-icons/io5'
import { userDetailsAction, userUpdateAction } from '../actions/auth'
import { USER_UPDATE_PROFILE_RESET } from '../types/auth'

function EditProfilePage() {

    const { user, success } = useSelector(state => state.auth);

    const [first, setFirst] = useState("")
    const [last, setLast] = useState("")
    const [email, setEmail] = useState("")
    const [description, setDescription] = useState("")
    const [achievements, setAchievements] = useState("")
    const [github, setGithub] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [userAvatar, setUserAvatar] = useState(null);
    const [image, setImage] = useState();


    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {

        if (!user || !user?.user?.email || success) {
            dispatch({ type: USER_UPDATE_PROFILE_RESET })
            dispatch(userDetailsAction(user?.user?.id))


        } else {
            setFirst(user?.user?.first_name)
            setLast(user?.user?.last_name)
            setEmail(user?.user?.email)
            setDescription(user?.description)
            setAchievements(user?.achievements)
            setGithub(user?.github)
            setLinkedin(user?.linkedin)
            setImage(`https://alumnihub.isavesit.org.in${user?.profile_image}`)
        }

    }, [dispatch, user, success])

    if(userAvatar == false){
        setUserAvatar(image)
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        try {
            let form_data = new FormData();
            form_data.append('id', user?.user?.id)
            form_data.append('first_name', first)
            form_data.append('last_name', last)
            form_data.append('email', email)
            form_data.append('description', description)
            form_data.append('achievements', achievements)
            form_data.append('github', github)
            form_data.append('linkedin', linkedin)
            if(userAvatar){
            form_data.append('profile_image', userAvatar) 
            }
            
            dispatch(userUpdateAction(form_data , navigate))
        } catch (error) {
        }
    }

    const onImageChange = (e) => {
        setUserAvatar(e.target.files[0])
        setImage(URL.createObjectURL(e.target.files[0]))
    }
    return (
        <>
            <Navbar />

            <div className="flex bg-background-color font-general">

                {/*  */}
                <div className="container md:mx-auto pt-32">
                    <div className="md:box-content xl:w-10/12 p-4 bg-nav-color text-white rounded-2xl mb-20 font-general shadow-2xl mx-2 xl:mx-auto">
                        <div class="grid md:grid-flow-col">
                            <div className="">
                                <h1 className="px-10 visible md:invisible text-3xl">Edit Profile</h1>
                                <input className='mt-10 m-auto' type="file" accept="image/*" onChange={onImageChange}/>
                                    <img src={image} className='rounded-full w-80 h-80 m-auto mt-10 object-cover'></img>
                                
                            </div>
                            <div className="col-span-1 mt-20 md:mt-0">
                                <h1 className="md:text-left px-10 invisible md:visible text-4xl">Edit Profile</h1>
                                <div className="grid md:grid-flow-col">
                                    <div>
                                        <p className="text-left px-8 py-2">First Name</p>
                                        <input type="text" placeholder="First Name" ddisabled value={first} className="px-8 py-3 placeholder-blueGray-300 relative  cursor-not-allowed text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring md:w-5/6" />
                                    </div>
                                    <div>
                                        <p className="text-left px-8 py-2">Last Name</p>
                                        <input type="text" placeholder="Last Name" value={last} className="px-8 py-3 placeholder-blueGray-300 relative cursor-not-allowed text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring md:w-5/6" />
                                    </div>
                                </div>
                                <p className="text-left px-8 py-2">VES Email Id</p>
                                <input type="text" placeholder="@ves.ac.in" value={email} className="px-8 py-3 placeholder-blueGray-300 relative cursor-not-allowed text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring md:w-11/12" disabled />

                                <p className="text-left px-8 py-2">Description</p>
                                <input type="text" placeholder="Description" value={description} onChange={(e) => { setDescription(e.target.value) }} className="px-8 py-3 placeholder-blueGray-300 relative text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring md:w-11/12" />

                                <p className="text-left px-8 py-2">Achievements and Skills</p>
                                <input type="text" placeholder="Achievements and Skills" value={achievements} onChange={(e) => { setAchievements(e.target.value) }} className="px-8 py-3 placeholder-blueGray-300 relative text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring md:w-11/12" />

                                <div className="grid md:grid-flow-col">
                                    <div>
                                        <p className="text-left px-8 py-2">GitHub Id</p>
                                        <input type="text" placeholder="GitHub Id" value={github} onChange={(e) => { setGithub(e.target.value) }} className="px-8 py-3 placeholder-blueGray-300 relative text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring md:w-5/6" />
                                    </div>
                                    <div>
                                        <p className="text-left px-8 py-2">Linkedin Profile Link</p>
                                        <input type="text" placeholder="Linkedin Profile" value={linkedin} onChange={(e) => { setLinkedin(e.target.value) }} className="px-8 py-3 placeholder-blueGray-300 relative text-black bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring md:w-5/6" />
                                    </div>
                                </div>
                                <div className="flex align-middle justify-center">
                                    <div onClick={submitHandler} className="bg-background-color cursor-pointer text-white block px-3 py-2 mt-6 w-40 rounded-md text-base font-medium">
                                        Save
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to={`/user/${user?.user?.id}`}><div className="fixed bottom-4 right-4 h-10 w-10 bg-white z-50 border-2 border-black rounded-md lg:w-max flex justify-center items-center lg:p-2">
                        <span className="hidden lg:block text-lg mr-2">Back</span>
                        <IoReturnDownBackOutline className="h-full w-full" />
                    </div></Link>
                </div>
                {/*  */}

            </div>
        </>
    )
}

export default EditProfilePage
