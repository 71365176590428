import React , {useState} from 'react'
import BatmanPic from '../../assets/batman.jpg'
import SidebarDropdown from '../Dropdown/SideBarDropdown'
import { slide as Menu } from 'react-burger-menu'
import './mobileStyle.css'
import {AiOutlineDoubleRight , AiOutlineDoubleLeft} from 'react-icons/ai'
import { useSwipeable } from "react-swipeable";
import { Link } from 'react-router-dom'
import {useSelector} from 'react-redux'



const swipeOpenMenuStyles = {
    float: "left",
    position: "fixed",
    width: "33%",
    height: "100%",
    zIndex:"1"
  };


function LeftSidebar() {

    const [isOpen, setOpen] = useState(false);
    const[scroll,setScroll] = useState(0)
    const[hideNav,setHideNav] = useState(false)

    const checkScroll = () => {
        if(window.scrollY > 200){
            setScroll(window.scrollY)
            setHideNav(window.scrollY > scroll)
        }
    }

    window.addEventListener("scroll" , checkScroll)

    const handlers = useSwipeable({
      trackMouse: true,
      onSwipedRight: () => setOpen(true)
    });


    const {user} = useSelector(state => state.auth);

    return (
        <>
        <div className="hidden lg:flex flex-col mt-40 shadow-2xl rounded-xl sticky top-40 bg-nav-color text-white">
           <Link to={`/user/${user?.user?.id}`}> <div className="flex justify-center items-center p-5 hover:bg-hover-dark cursor-pointer border-b-2 rounded-t-xl">
                <img src={`https://alumnihub.isavesit.org.in${user?.profile_image}`} alt="profilePic" className="w-10 h-10 rounded-full mx-2"></img>
                <p>{user?.user?.first_name} {user?.user?.last_name}</p>
            </div></Link>
            <div className="cursor-pointer border-b-2">
                {/* <Dropdown/> */}
                <SidebarDropdown/>
            </div>
            <Link to="/createPost"><div className="p-5 hover:bg-hover-dark cursor-pointer border-b-2">
                Create Post
            </div></Link>
            <Link to="/savedPosts">
            <div className="p-5 hover:bg-hover-dark cursor-pointer rounded-b-lg">
                Saved Posts
            </div></Link>
        </div>

        <div {...handlers} style={swipeOpenMenuStyles} />

        <div className={hideNav ? "hidden duration-1000" : " "}>
        <Menu 
         isOpen={isOpen}
         onStateChange={s => setOpen(s.isOpen)}
         pageWrapId={"page-wrap"}
         outerContainerId={"App"}
         customBurgerIcon={ isOpen ? <AiOutlineDoubleLeft/> : <AiOutlineDoubleRight /> }
          className="flex flex-col mt-40 shadow-2xl rounded-xl lg:hidden duration-1000"
        >
            <Link to={`/user/${user?.user?.id}`}><div className="flex flex-col justify-center items-center p-5 hover:bg-hover-dark  border-b-2 rounded-t-xl">
                <img src={`https://alumnihub.isavesit.org.in${user?.profile_image}`} alt="profilePic" className="w-10 h-10 rounded-full mx-auto"></img>
                <p>{user?.user?.first_name} {user?.user?.last_name}</p>
            </div>
            </Link>
            <div className=" border-b-2 max-h-48 overflow-y-scroll">
                {/* <Dropdown/> */}
                <SidebarDropdown/>
            </div>
            <Link to="/createPost"><div className="p-5 hover:bg-hover-dark  border-b-2 flex justify-center items-center">
                Create Post
            </div>
            </Link>
            <Link to="/savedPosts"><div className="p-5 hover:bg-hover-dark  rounded-b-lg flex justify-center items-center">
                Saved Posts
            </div>
            </Link>
        </Menu></div>
        </>
    )
}

export default LeftSidebar
