import React, {useEffect } from 'react'
import { Link,useParams } from 'react-router-dom'
import { FaLinkedin , FaGithub ,FaUserEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux'
import {userDetailsAction,removeUser} from '../actions/auth'



function Profile({author}) {

    const dispatch = useDispatch()
    
    const {user} = useSelector(state => state.auth);
    useEffect(() => {
        dispatch(userDetailsAction())     
        return () =>{
            dispatch(removeUser());
          }
    },[dispatch])

  return (
    
    <div className="md:box-content lg:w-1/4 p-4 bg-nav-color text-white rounded-2xl my-10 font-general shadow-2xl mx-auto ">
                    <div className="flex align-middle justify-center relative bottom-16">
                        <img className="w-40 h-40 rounded-full" src={`https://alumnihub.isavesit.org.in${author?.profile_image}`}></img>
                    </div>

                        {
                            (user?.user?.email) == (author?.user?.email) &&(
                                <Link to="/editProfile"><div className="flex justify-center items-center bg-white text-nav-color w-max mx-auto mb-4 rounded-lg px-4 py-2 ">
                                 <FaUserEdit className="text-2xl" />
                                    <p className="text-2xl ml-2">Edit Profile</p>
                                </div></Link>
                            )
                        }

                <h1 className="px-10 pb-5 text-4xl">{`${author?.user?.first_name} ${author?.user?.last_name}`}</h1>
                <p className=""><span className="font-black text-xl">Ves Email Id :</span>{author?.user?.email}</p><br/>
                <p className=""><span className="font-black text-xl">Description :</span>{author?.description}</p><br/>
                <p className=""><span className="font-black text-xl">Achievements and Skills :</span> {author?.achievements}</p><br/>
                <ul className="flex justify-center w-full">
                <li className="px-4 md:px-8 text-3xl md:text-5xl transform hover:-translate-y-3 cursor-pointer duration-500"><a href={`https://github.com/`+author?.github} target="_blank"><FaGithub/></a></li>
                    <li className="px-4 md:px-8 text-3xl md:text-5xl transform hover:-translate-y-3 cursor-pointer duration-500"><a href={author?.linkedin} target="_blank"><FaLinkedin/></a></li>
                </ul>
                </div>

  )
}

export default Profile