import { post } from '../types';

const INITIAL_STATE = {
    isPostListLoading : false,
    isSavePostLoading: false,
    isPostDetailLoading :false,
    isCreatePostLoading : false,
    isUserPostListLoading : false,
    isUserPostDeleteLoading:false,
    isUserSavedPostsLoading:false,
    isUserProfileLoading:false,
    isPostTagsLoading:false,
    isTagPostLoading:false,
    

    posts : null,
    postDetail : null,
    postsCreate : null,
    userPosts : null,
    userPostDelete:null,
    userSavedPosts:null,
    userProfile:null,
    postTags:null,
    tagPost:null,

    postListError :null,
    postSaveError : null,
    postDetailError : null,
    postCreateError : null,
    userPostListError : null,
    userPostDeleteError:null,
    userSavedPostsError:null,
    userProfileError:null,
    postTagsError:null,
    tagPostError:null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
        case post.POST_LIST_REQUEST:
            return {
                ...state,
                isPostListLoading: action.payload
            }
        case post.POST_LIST_SUCCESS:
            return {
                ...state,
                posts : action.payload
            }

        case post.POST_LIST_FAILURE:
            return{
                ...state,
                postListError : action.payload.message
            }
        case post.POST_DETAILS_REQUEST:
            return {
                ...state,
                isPostDetailLoading: action.payload
            }
        case post.POST_DETAILS_SUCCESS:
            return {
                ...state,
                postDetail : action.payload
            }

        case post.POST_DETAILS_FAILURE:
            return{
                ...state,
                postDetailError : action.payload.message
            }
        case post.SAVE_POST_REQUEST:
            return {
                ...state,
                isSavePostLoading: action.payload
            }
        case post.POST_LIST_SUCCESS:
            return {
                ...state,
                posts: action.payload
            }

        case post.POST_CREATE_FAILURE:
            return{
                ...state,
                postSaveError : action.payload.message
            }
        case post.SAVE_POST_REQUEST:
            return {
                ...state,
                isCreatePostLoading: action.payload
            }
        case post.POST_CREATE_SUCCESS:
            return {
                ...state,
                postsCreate: action.payload
            }

        case post.POST_CREATE_FAILURE:
            return{
                ...state,
                postCreateError : action.payload.message
            }
        
        case post.USER_POST_LIST_REQUEST:
            return {
                ...state,
                isUserPostListLoading: action.payload
            }
        case post.USER_POST_LIST_SUCCESS:
            return {
                ...state,
                userPosts : action.payload
            }
    
        case post.USER_POST_LIST_FAILURE:
            return{
                ...state,
                userPostListError : action.payload.message
            }
        case post.USER_UPDATE_POST_RESET:
            return{}

        case post.USER_POST_DELETE_REQUEST:
            return {
                ...state,
                isUserPostDeleteLoading: action.payload
            }
        case post.USER_POST_DELETE_SUCCESS:
            return {
                ...state,
                userPostDelete : action.payload
            }
    
        case post.USER_POST_DELETE_FAILURE:
            return{
                ...state,
                userPostDeleteError : action.payload.message
            }
        case post.USER_SAVED_POSTS_REQUEST:
            return {
                ...state,
                isUserSavedPostsLoading: action.payload
            }
        case post.USER_SAVED_POSTS_SUCCESS:
            return {
                ...state,
                userSavedPosts : action.payload
            }
    
        case post.USER_SAVED_POSTS_FAILURE:
            return{
                ...state,
                userSavedPostsError : action.payload.message
            }
        case post.USER_PROFILE_REQUEST:
                return {
                    ...state,
                    isUserProfileLoading: action.payload
                }
        case post.USER_PROFILE_SUCCESS:
                return {
                    ...state,
                    userProfile : action.payload
                }
        
        case post.USER_PROFILE_FAILURE:
                return{
                    ...state,
                    userProfileError : action.payload.message
                }
        case post.GET_POST_TAGS_REQUEST:
                    return {
                        ...state,
                        isPostTagsLoading: action.payload
                    }
        case post.GET_POST_TAGS_SUCCESS:
                    return {
                        ...state,
                        postTags : action.payload
                    }
            
        case post.GET_POST_TAGS_FAILURE:
                    return{
                        ...state,
                        postTagsError : action.payload.message
                    }
        case post.GET_QUICKLINKS_REQUEST:
                        return {
                            ...state,
                            isQuickLinksLoading: action.payload
                        }
        case post.GET_QUICKLINKS_SUCCESS:
                        return {
                            ...state,
                            quickLinks : action.payload
                        }
                
        case post.GET_QUICKLINKS_FAILURE:
                        return{
                            ...state,
                            quickLinksError : action.payload.message
                        }
        case post.GET_TAG_POST_REQUEST:
                            return {
                                ...state,
                                isTagPostLoading: action.payload
                            }
        case post.GET_TAG_POST_SUCCESS:
                            return {
                                ...state,
                                tagPost : action.payload
                            }
                    
        case post.GET_TAG_POST_FAILURE:
                            return{
                                ...state,
                                tagPostError : action.payload.message
                            }
        case post.POST_DETAILS_REMOVE:
                    return{ }
        default:
            return state
    }
}
