import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../Components/Footer'
import LandingPageNavbar from '../Components/Navbar/LandingPage-navbar'
import {useNavigate} from 'react-router-dom'
import OtpInput from 'react-otp-input';
import '../Components/Otp/Otp.css'
import { checkOtpAction } from '../actions/auth'



function OtpPage() {

    const [otp, setOtp] = useState('');

    const {isLoginButtonLoading} = useSelector(state => state.auth)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleOtpChange = (otp) => {
        setOtp(otp);
    }

    const checkOtp = (e) => {
        e.preventDefault();
        dispatch(checkOtpAction(otp, navigate));        
    }


    return (
        <>
            <LandingPageNavbar/>  
          <div className="bg-gradient-to-b from-background-color to-white ">
                <div className="container md:mx-auto pt-52">
                        <form>
                        <div className="h-92 w-72 sm:w-3/4 lg:w-1/2 p-2 sm:p-4 bg-nav-color text-white rounded-2xl font-general shadow-2xl mx-auto">
                            <h1 className="text-3xl pt-8">Enter Verification Code</h1>
                            <h4 className="text-md pb-8">A 4 digit OTP has been sent to your VES Email Id</h4>
                            <div className="flex flex-col mb-3 pt-0 mx-auto w-full justify-center items-center text-black">
                                <OtpInput
                                inputStyle="inputStyle"
                                    value={otp}
                                    onChange={handleOtpChange}
                                    numInputs={4}
                                    separator={<span></span>}
                                    shouldAutoFocus={true}
                                    isInputNum={true}
                                />
                                <button type="submit" onClick={checkOtp} href="#" className="bg-background-color cursor-pointer text-white block px-3 py-2 mt-16 w-40 mx-auto rounded-md text-base font-medium">
                                    {isLoginButtonLoading ? 'Loading...':'Login'}
                                </button>
                            </div>
                        </div>
                        </form>
                    </div>
                <Footer/>
          </div>
        
        </>
    )
}

export default OtpPage
