import { auth } from '../types'
import axios from 'axios'
import { baseURL } from '../api'
import {toast} from 'react-toastify';

// actions without api call
export const setUserEmailAction = (email) => async (dispatch) => {
    dispatch({type: auth.SET_USER_EMAIL,payload: email})
}



// actions with api call
export const checkUserAction = (email,navigate) => async (dispatch) => {
    try{
        dispatch({type: auth.CHECK_USER_REQUEST, payload:true})
        const config = {headers: {'Content-type': 'application/json'}}
        const {data} = await axios.post(`${baseURL}/users/login/`,{'username': email,'email': email, 'password':`alumnihub`},config)
        dispatch({type:auth.CHECK_USER_SUCCESS, payload:data})
        dispatch({type: auth.CHECK_USER_REQUEST, payload:false})
        localStorage.setItem('AlumniHubUserAuth',JSON.stringify(data));
        navigate('/otp');
    }catch(error){
        dispatch({
            type: auth.CHECK_USER_FAILURE,
            payload: error.response && error.response.data.message 
                ? error.response.data.message
                :error.message
        })
        dispatch({type: auth.CHECK_USER_REQUEST, payload:false})
        toast.error('Email ID Not Identify', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            toast.info('Not an ISA Member yet. Take membership Now!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
    }
}

export const checkOtpAction = (otp, navigate) => async (dispatch) => {
    debugger
    try{
        dispatch({type: auth.CHECK_OTP_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.post(`${baseURL}/users/verifyotp/`,{'otp':otp},config)
        if(data.status === 1){
            dispatch({type:auth.CHECK_OTP_SUCCESS, payload:data})
            dispatch({type: auth.CHECK_OTP_REQUEST, payload:false})
            localStorage.setItem('AlumniHubUserVerified',true);
            toast.success('Login Successfull!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
            navigate('/posts');
        }else if(data.status === 0){
            dispatch({type:auth.CHECK_OTP_FAILURE, payload:data})
            dispatch({type: auth.CHECK_OTP_REQUEST, payload:false})
            toast.error('Login Failed. Check OTP!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
    }catch(error){
        dispatch({
            type: auth.CHECK_OTP_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: auth.CHECK_OTP_REQUEST, payload:false})
    }
}

export const userDetailsAction = () => async (dispatch) => {
    try{
        dispatch({type: auth.USER_DETAILS_REQUEST, payload:true})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/profile/`,config)
        if(data.status === 1){
            dispatch({type:auth.USER_DETAILS_SUCCESS, payload:data.profile})
            dispatch({type: auth.USER_DETAILS_REQUEST, payload:false})
        }else if(data.status === 0){
            dispatch({type:auth.USER_DETAILS_FAILURE, payload:data})
            dispatch({type: auth.USER_DETAILS_REQUEST, payload:false})
        }
    }catch(error){
        dispatch({
            type: auth.USER_DETAILS_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
        })
        dispatch({type: auth.USER_DETAILS_REQUEST, payload:false})
    }
}

export const userUpdateAction = (user,navigate) => async (dispatch) => {
    try{
        dispatch({type: auth.USER_DETAILS_REQUEST})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-Type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.put(`${baseURL}/users/updateprofile/`,user,config)
        debugger
        if(data.status === 1){
            dispatch({type:auth.USER_DETAILS_SUCCESS, payload:data.profile})
            dispatch({type: auth.USER_DETAILS_REQUEST, payload:false})
            dispatch({type:auth.CHECK_USER_SUCCESS, payload:data})
            navigate('/posts')
            toast.success('Profile Updated Successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }else if(data.status === 0){
            dispatch({type:auth.USER_DETAILS_FAILURE, payload:data})
            dispatch({type: auth.USER_DETAILS_REQUEST, payload:false})
            toast.error('Error Updating Profile!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });

        }
    }catch(error){
        dispatch({
            type: auth.USER_DETAILS_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
            
        })
        dispatch({type: auth.USER_DETAILS_REQUEST, payload:false})
    }
}

export const userLogoutAction = (navigate) => async (dispatch) => {
    try{
        dispatch({type: auth.USER_LOGOUT_REQUEST})
        const {access} = JSON.parse(localStorage.getItem('AlumniHubUserAuth'));
        const config = {headers: {'Content-type': 'application/json',Authorization : `Bearer ${access}`}}
        const {data} = await axios.get(`${baseURL}/users/logout/`,config)
        if(data.status === 1){
            dispatch({type:auth.USER_LOGOUT_SUCCESS})
            dispatch({type: auth.USER_LOGOUT_REQUEST, payload:false})            
            navigate('/')
            toast.info('Logged Out!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }else if(data.status === 0){
            dispatch({type:auth.USER_LOGOUT_FAILURE, payload:data})
            dispatch({type: auth.USER_LOGOUT_REQUEST, payload:false})
            
        }
    }catch(error){
        dispatch({
            type: auth.USER_LOGOUT_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                :error.message
            
        })
        dispatch({type: auth.USER_LOGOUT_REQUEST, payload:false})
    }
}
export const removeUser = () => async (dispatch) => {
    dispatch({type: auth.USER_DETAILS_REMOVE})
    
}