import React, { Component } from 'react'
import './ProfilePhoto.css'

export default class Photo extends Component {
    state={
        profileImg:'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
      }
      imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () =>{
          if(reader.readyState === 2){
            this.setState({profileImg: reader.result})
          }
        }
        reader.readAsDataURL(e.target.files[0])
      };
    render() {
        const { profileImg} = this.state
        return (
            <div>
					<div className="img-holder py-10 mb-16 ">
						<img src={profileImg} alt="" id="img" className="img" />
            
            <div className="flex align-middle justify-center">
            <input type="file" accept="image/*" name="image-upload" id="input" className="w-60 py-6 md:py-10"  onChange={this.imageHandler} />
            </div>
					</div>	
				</div>
        )
    }
}
