import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import IsaLogo from "../../assets/IsaLogo.png"
import {Link} from "react-router-dom"


function LandingPageNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [navbar , setNavbar] = useState(false)

  const changeNavBG = () => {
    if (window.scrollY >= 90 && window.screen.width >= 1024 || window.scrollY >= 75 && (window.screen.width >= 768) || window.scrollY >= 45 && (window.screen.width >= 425) || window.scrollY >= 45 && window.screen.width < 425 ){
      setNavbar(true)
    }else{
      setNavbar(false)
    }
    
  }

  window.addEventListener("scroll" , changeNavBG)

  return (
    <div>
      <nav className={navbar ? "bg-nav-color h-24 fixed top-0 w-full z-10 duration-1000	shadow-2xl drop-shadow-2xl" : " bg-transparent h-24 fixed top-0 w-full z-10 duration-1000"}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-24 w-full">
              <Link to="/"><div className="flex-shrink-0 h-20 w-20">
                <img
                  className="h-20 w-20"
                  src={IsaLogo}
                  alt="Workflow"
                />
              </div></Link>

              <div className={navbar ? "font-heading text-2xl md:text-5xl lg:text-7xl text-white duration-1000 shadow-inner" : "font-heading text-2xl md:text-5xl lg:text-7xl "}>Alumni Hub</div>
              
              <div className="hidden md:block">
                <div className="flex items-baseline space-x-4">
                  <Link to="/email"><a className="text-white py-3 px-6 rounded-md text-sm font-medium ">
                    <button className={navbar ? "bg-background-color hover:bg-hover-color text-white font-semibold py-3 px-6 w-full rounded shadow flex text-lg" : "bg-nav-color hover:bg-hover-color text-white font-semibold py-3 px-6 w-full rounded shadow flex text-lg"}>
                         <span>Login</span>
                   </button>
                   </a></Link>
                </div>
              </div>
            <div className="-mr-2 flex md:hidden h-20 w-20 justify-center items-center">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 ring-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Login</span>
                {!isOpen ? (
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <Link to="/email"><a
          
                  className="bg-nav-color hover:bg-background-color text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Login
                </a></Link>
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}

export default LandingPageNavbar;
