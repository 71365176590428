import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Components/Navbar/Navbar';
import LeftSidebar from '../Components/Sidebar/LeftSidebar';
import RightSidebar from '../Components/Sidebar/RightSidebar';
import Post from '../Components/Post'
import { userSavedPostsAction } from '../actions/post'
import Loader from '../Components/Loader';
import { IoReturnDownBackOutline } from 'react-icons/io5'
import {Link} from 'react-router-dom';

function UserSavedPostsFeedPage() {

    const dispatch = useDispatch();
    const {isUserSavedPostsLoading,userSavedPosts,userSavedPostsError} = useSelector(state => state.post);
    
    useEffect(() => {
        dispatch(userSavedPostsAction());

    },[] )
    return (
        <>
          <Navbar/>
            <div className="flex bg-background-color font-general min-h-screen">
                {/* <div className="lg:w-1/4 box-border px-4"><LeftSidebar /></div> */}
                <div className="w-full lg:w-1/2 box-border px-4 mx-auto">

                    {/*  */}
                    <div className="mt-32 mb-4">
                    {isUserSavedPostsLoading ? <Loader/>
                : userSavedPostsError ? <h1>Error</h1>
                    : <div>
                        <div className="text-4xl font-bold sticky justify-center items-center w-full ">Saved Posts</div>
                        
                        {userSavedPosts?.slice(0).reverse().map(p => (

                            <Post
                            key={p.id}
                            post_id ={p?.id}
                        
                            name={`${p.user.first_name} ${p.user.last_name}`}
                            title={p.heading}
                            message = {p.description}
                            email={p.user.email}
                            timestamp={p.created_at}
                            profilePic={`https://alumnihub.isavesit.org.in${p.profile.profile_image}`}

                            postImage={p.post_image ? `https://alumnihub.isavesit.org.in${p.post_image}` : null}
                            tags={
                                p.tags
                            }
                            isAlumni={true}
                            authorProfile = {p.profile}
                            author_id = {p?.user?.id}
                            />
                        
                        ))}
                    </div>
            }
            
            

            
        </div>
                    {/*  */}

                </div>
                {/* <div className="hidden lg:block lg:w-1/4 box-border px-4"><RightSidebar/></div> */}
                <Link to="/posts"><div className="fixed bottom-4 right-4 h-10 w-10 bg-white z-50 border-2 border-black rounded-md lg:w-max flex justify-center items-center lg:p-2">
                <span className="hidden lg:block text-lg mr-2">Back</span>
                <IoReturnDownBackOutline className="h-full w-full"/>
            </div></Link>
            </div>  
        </>
    )
}

export default UserSavedPostsFeedPage
